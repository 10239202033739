import { unwrapResult } from '@reduxjs/toolkit';
import { FindOneAnalysisWithPreviousResDto } from 'api/generated';
import { EditIcon, PrinterIcon } from 'assets/svg';
import classNames from 'classnames';
import { selectDownloadingAnalysisPdfStatus, selectLoading } from 'features/analyzes/selectors';
import {
  getAnalysisPdfWithPreviousAsync,
  getAnalysisWithPreviousAsync,
  getPatientAnalysisPdfWithPreviousAsync,
} from 'features/analyzes/thunks';
import { selectRole } from 'features/auth/selectors';
import { RolesEnum } from 'features/auth/types';
import { selectBiomarkers } from 'features/biomarkers/selectors';
import { expertGetAnalysisAsync, getPatientByIdAsync } from 'features/expert/thunks';
import { useClientSize } from 'hooks';
import useWindowWidth from 'hooks/useWindowWidth';
import { LayoutWithBackOptions } from 'layouts';
import moment from 'moment';
import plural from 'plural-ru';
import { FC, useEffect, useState } from 'react';
import { Col, Container, Hidden, Row, Visible } from 'react-grid-system';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { notify } from 'services/notificationService';
import { useAppDispatch, useAppSelector } from 'store/reducers';
import { LoadingStatus } from 'types';
import { CommonRoutes, ExpertRoutes, PatientRoutes } from 'types/routes';
import { UserGender } from 'types/user';
import { ButtonDefault, IconButton, Spinner } from 'UIcomponents';
import { downloadBlob } from 'utils/downloadBlob';
import { printPdfBlob } from 'utils/printPdfBlob';
import { differenceOfYears } from 'utils/time';

import { BiomarkerItem } from './components/BiomarkerItem';
import styles from './styles.module.scss';

export const Analysis: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'analysis' });

  const { getIsBreakpoint } = useClientSize();
  const isMobile = getIsBreakpoint('sm');

  const { id: analysisId } = useParams<{ id: string }>();
  const width = useWindowWidth();
  const { state } = useLocation<{ activePatientId: string; fromEdit?: boolean; fromHistory?: boolean }>();
  const role = useAppSelector(selectRole);
  const biomarkers = useAppSelector(selectBiomarkers);
  const [pdfPrinting, setPdfPrinting] = useState(false);
  const [pdfDownloading, setPdfDownloading] = useState(false);
  const patientData = useAppSelector((state) => {
    if (role === RolesEnum.EXPERT) return state.expert.activePatient;

    return state.patient.me;
  });

  const pdfLoading = useAppSelector(selectDownloadingAnalysisPdfStatus) === LoadingStatus.pending;

  const dispatch = useAppDispatch();
  const [analysis, setAnalysis] = useState<FindOneAnalysisWithPreviousResDto>();

  const analysisKinds = useAppSelector((state) => state.analyzes.analysisKinds);
  const analysisKind = analysisKinds.find((ak) => ak.id === analysis?.currentAnalysis.analysisKindId);
  const analysisKindLabel = analysisKind ? analysisKind.label : t('individualAnalysis');
  const [ageByAnalysis, setAgeByAnalysis] = useState<number>(0);

  const history = useHistory();
  const loadingStatus = useSelector(selectLoading);
  const isLoading = loadingStatus === LoadingStatus.pending;
  const isPatientData = Boolean(patientData.id);

  const getAnalysis = async (analysisId: string) => {
    if (role !== 'PATIENT') {
      await dispatch(expertGetAnalysisAsync(analysisId))
        .then(unwrapResult)
        .then((result) => {
          setAnalysis({ currentAnalysis: result.currentAnalysis, previousAnalysis: result.previousAnalysis });
        })
        .catch((error) => {
          notify('error', error.message);
          history.push(CommonRoutes.ANALYZES_HISTORY);
        });
    } else {
      if (Object.values(patientData).length === 0) {
        dispatch(getPatientByIdAsync(state.activePatientId));
      }

      await dispatch(getAnalysisWithPreviousAsync(analysisId))
        .then(unwrapResult)
        .then((result) => {
          setAnalysis(result);
        })
        .catch((error) => {
          notify('error', error.message);
          history.push(CommonRoutes.ANALYZES_HISTORY);
        });
    }
  };

  const handleEditClick = () => {
    if (role === 'PATIENT') {
      history.push(PatientRoutes.EDIT_ANALYSIS(analysisId));
    } else {
      history.push(ExpertRoutes.EDIT_ANALYSIS(analysisId));
    }
  };

  const handlePrintClick = async () => {
    setPdfPrinting(true);

    if (!analysis) {
      throw new Error('analysis is not defined');
    }

    await dispatch(getAnalysisPdfWithPreviousAsync(analysisId))
      .then(unwrapResult)
      .then((res) => printPdfBlob(res as unknown as Blob))
      .catch((error) => {
        notify('error', t('pdfParsingLimitError'));
      })
      .finally(() => {
        setPdfPrinting(false);
      });
  };

  const handleExportInPdfClick = async () => {
    setPdfDownloading(true);
    if (!analysis) {
      throw new Error('analysis is not defined');
    }

    const pdfFilename = `${analysisKind ? analysisKind.label : t('individualAnalysis')}_${moment(
      analysis.currentAnalysis.date,
    ).format('DD.MM.YYYY')}_${patientData.name}.pdf`;
    await dispatch(getAnalysisPdfWithPreviousAsync(analysisId))
      .then(unwrapResult)
      .then((res) => downloadBlob(res as unknown as Blob, pdfFilename))
      .catch(() => {
        notify('error', t('pdfParsingLimitError'));
      })
      .finally(() => {
        setPdfDownloading(false);
      });
  };

  const handleDownloadPatientAnalysis = async () => {
    setPdfPrinting(true);

    if (!analysis) {
      throw new Error('analysis is not defined');
    }

    await dispatch(getPatientAnalysisPdfWithPreviousAsync({ analysisId, patientId: patientData.id }))
      .then(unwrapResult)
      .then((res) => printPdfBlob(res as unknown as Blob))
      .catch((error) => {
        notify('error', error.message);
      })
      .finally(() => {
        setPdfPrinting(false);
      });
  };

  const handlePrintPatientAnalysis = async () => {
    setPdfDownloading(true);
    if (!analysis) {
      throw new Error('analysis is not defined');
    }

    const pdfFilename = `${analysisKind ? analysisKind.label : t('individualAnalysis')}_${moment(
      analysis.currentAnalysis.date,
    ).format('DD.MM.YYYY')}_${patientData.name}.pdf`;
    await dispatch(getPatientAnalysisPdfWithPreviousAsync({ analysisId, patientId: patientData.id }))
      .then(unwrapResult)
      .then((res) => downloadBlob(res as unknown as Blob, pdfFilename))
      .catch((error) => {
        notify('error', error.message);
      })
      .finally(() => {
        setPdfDownloading(false);
      });
  };

  const hasPreviousAnalysis = analysis?.previousAnalysis !== undefined;

  useEffect(() => {
    getAnalysis(analysisId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    patientData.id &&
      analysis?.currentAnalysis.patientId &&
      patientData.id !== analysis?.currentAnalysis.patientId &&
      history.push(CommonRoutes.ANALYZES_HISTORY);
  }, [analysis?.currentAnalysis.patientId, history, patientData.id]);

  useEffect(() => {
    if (analysis && patientData.birthday) {
      return setAgeByAnalysis(
        differenceOfYears(new Date(patientData.birthday), new Date(analysis.currentAnalysis.date)),
      );
    }
  }, [analysis, patientData.birthday]);

  if (!isPatientData) {
    history.push('/dashboard');
  }

  return (
    <LayoutWithBackOptions
      backOptions={{
        text: t('title'),
        allowGoBack: !!state?.fromEdit,
        backFunction: () => history.goBack(),
      }}
      additionalNode={{
        node: (
          <div className={classNames(styles['analysis__actions'], 'flexbox-inline justify-center align-center')}>
            <IconButton
              variant="secondary"
              icon={<EditIcon />}
              onClick={handleEditClick}
              containerClassNames="flexbox-inline justify-center align-center"
            />
            {role === 'PATIENT' ? (
              <>
                <IconButton
                  variant="secondary"
                  icon={<PrinterIcon />}
                  onClick={handlePrintClick}
                  containerClassNames={classNames(styles['print-button'], 'flexbox-inline justify-center align-center')}
                  isDisabled={pdfLoading || pdfPrinting}
                  isLoading={pdfLoading && pdfPrinting}
                />
                {!isMobile && (
                  <ButtonDefault
                    text={t('getResult')}
                    onClick={handleExportInPdfClick}
                    containerClassNames={classNames(styles['generate-button'])}
                    isDisabled={pdfLoading || pdfDownloading}
                    isLoading={pdfLoading && pdfDownloading}
                  />
                )}
              </>
            ) : (
              <>
                <IconButton
                  variant="secondary"
                  icon={<PrinterIcon />}
                  onClick={handleDownloadPatientAnalysis}
                  containerClassNames={classNames(styles['print-button'], 'flexbox-inline justify-center align-center')}
                  isDisabled={pdfLoading || pdfPrinting}
                  isLoading={pdfLoading && pdfPrinting}
                />
                {!isMobile && (
                  <ButtonDefault
                    text={t('getResult')}
                    onClick={handlePrintPatientAnalysis}
                    containerClassNames={classNames(styles['generate-button'])}
                    isDisabled={pdfLoading || pdfDownloading}
                    isLoading={pdfLoading && pdfDownloading}
                  />
                )}
              </>
            )}
          </div>
        ),
        position: 'above-title',
      }}>
      <>
        {analysis && !isLoading ? (
          <div className={classNames(styles['container'])}>
            <div className={classNames(styles['analysis__header'])}>
              <div className={classNames('flexbox', 'justify-between', styles['analysis-info'])}>
                <h4 className={classNames(styles['analysis-kind'])}>{analysisKindLabel}</h4>
                <div
                  className={classNames(
                    'flexbox',
                    'justify-start',
                    'align-top',
                    'body',
                    'body_medium',
                    styles['analysis-date'],
                  )}>
                  <div className={classNames(styles['analysis__label'])}>{t('date')}:&nbsp;</div>
                  <div className={classNames(styles['analysis__value'], styles['analysis-date__value'])}>
                    {moment(analysis.currentAnalysis.date).format('DD.MM.YYYY')}
                  </div>
                </div>
              </div>

              <div className={classNames('flexbox', 'justify-start', styles['user-info'])}>
                <div className={classNames('flexbox', 'direction-column', styles['user-info__column'])}>
                  <div
                    className={classNames(
                      'flexbox',
                      'justify-start',
                      'body',
                      'body_medium',
                      styles['analysis__user-name'],
                    )}>
                    <div className={classNames(styles['analysis__label'])}>{t('name')}:&nbsp;</div>
                    <div className={classNames(styles['analysis__value'])}>{patientData.name}</div>
                  </div>

                  <div
                    className={classNames(
                      'flexbox',
                      'justify-start',
                      'body',
                      'body_medium',
                      styles['analysis__user-age'],
                    )}>
                    <div className={classNames(styles['analysis__label'])}>{t('age')}:&nbsp;</div>
                    <div className={classNames(styles['analysis__value'])}>
                      {ageByAnalysis} {plural(ageByAnalysis, t('year'), t('years'), t('yearPlural'))}
                    </div>
                  </div>
                </div>

                <div className={classNames('flexbox', 'direction-column', styles['user-info__column'])}>
                  <div
                    className={classNames(
                      'flexbox',
                      'justify-start',
                      'body',
                      'body_medium',
                      styles['analysis__user-gender'],
                    )}>
                    <div className={classNames(styles['analysis__label'])}>{t('gender')}:&nbsp;</div>
                    <div className={classNames(styles['analysis__value'])}>{UserGender[patientData.gender]}</div>
                  </div>

                  {analysis?.currentAnalysis.biologicalAge && (
                    <div
                      className={classNames(
                        'flexbox',
                        'justify-start',
                        'body',
                        'body_medium',
                        styles['analysis__user-biologic-age'],
                      )}>
                      <div className={classNames(styles['analysis__label'])}>{t('bioAge')}:&nbsp;</div>
                      <div className={classNames(styles['analysis__value'])}>
                        {Math.floor(analysis.currentAnalysis.biologicalAge)}{' '}
                        {plural(
                          Math.floor(analysis.currentAnalysis.biologicalAge),
                          t('year'),
                          t('years'),
                          t('yearPlural'),
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className={classNames(styles['analysis__header--mobile'])}>
              <h4 className={classNames(styles['analysis-kind'])}>{analysisKindLabel}</h4>

              <div className={classNames('flexbox', 'justify-between', styles['user-info'])}>
                <div className={classNames('flexbox', 'direction-column', styles['user-info__column'])}>
                  <div
                    className={classNames(
                      'flexbox',
                      'justify-start',
                      'body',
                      'body_medium',
                      styles['analysis__user-name'],
                    )}>
                    <div className={classNames(styles['analysis__label'])}>{t('name')}:&nbsp;</div>
                    <div className={classNames(styles['analysis__value'])}>{patientData.name}</div>
                  </div>

                  <div
                    className={classNames(
                      'flexbox',
                      'justify-start',
                      'body',
                      'body_medium',
                      styles['analysis__user-gender'],
                    )}>
                    <div className={classNames(styles['analysis__label'])}>{t('gender')}:&nbsp;</div>
                    <div className={classNames(styles['analysis__value'])}>{UserGender[patientData.gender]}</div>
                  </div>

                  <div
                    className={classNames(
                      'flexbox',
                      'justify-start',
                      'body',
                      'body_medium',
                      styles['analysis__user-age'],
                    )}>
                    <div className={classNames(styles['analysis__label'])}>{t('age')}:&nbsp;</div>
                    <div className={classNames(styles['analysis__value'])}>
                      {ageByAnalysis} {plural(ageByAnalysis, t('year'), t('years'), t('yearPlural'))}
                    </div>
                  </div>
                </div>

                <div className={classNames('flexbox', 'direction-column', 'align-end', styles['user-info__column'])}>
                  <div className={classNames('flexbox', 'body', 'body_medium', styles['analysis-date'])}>
                    <div className={classNames(styles['analysis__label'])}>{t('age')}:&nbsp;</div>
                    <div className={classNames(styles['analysis__value'], styles['analysis-date__value'])}>
                      {moment(analysis.currentAnalysis.date).format('DD.MM.YYYY')}
                    </div>
                  </div>

                  {patientData.biologicalAge && (
                    <div
                      className={classNames('flexbox', 'body', 'body_medium', styles['analysis__user-biologic-age'])}>
                      <div className={classNames(styles['analysis__label'])}>{t('bioAgeShort')}:&nbsp;</div>
                      <div className={classNames(styles['analysis__value'])}>
                        {Math.floor(patientData.biologicalAge)}{' '}
                        {plural(Math.floor(patientData.biologicalAge), t('year'), t('years'), t('yearPlural'))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <Container
              className={classNames(styles['biomarkers'])}
              style={{ marginLeft: 0, marginRight: 0, maxWidth: 'unset' }}>
              <Row
                className={classNames(styles['biomarkers__header'])}
                style={{ justifyContent: width >= 768 ? 'space-between' : '' }}>
                <Col
                  className={classNames(styles['biomarkers__header-name'])}
                  md={4}
                  sm={4}
                  xs={4}
                  style={{ padding: '0' }}>
                  {t('biomarker')}
                </Col>

                {hasPreviousAnalysis && (
                  <Col
                    md={2}
                    sm={2}
                    xs={4}
                    className={classNames('flexbox', 'justify-center')}
                    style={{ padding: '0' }}>
                    {t('previousValue')}
                  </Col>
                )}

                <Hidden sm xs>
                  <Col md={2} sm={0} xs={0} style={{ padding: '0' }}>
                    {t('value')}
                  </Col>
                  <Col md={2} sm={0} xs={4} style={{ padding: '0' }}>
                    {t('normalZone')}
                  </Col>
                </Hidden>
                <Visible sm xs>
                  <Col md={0} sm={6} xs={4} style={{ padding: '0' }}>
                    {t('currnetValue')}
                  </Col>
                </Visible>
                <Hidden sm xs>
                  <Col md={2} sm={0} xs={0} style={{ padding: '0' }}>
                    {t('units')}
                  </Col>
                </Hidden>
              </Row>

              {analysis.currentAnalysis.patientAnalysisBiomarkers?.map((element) => {
                const biomarker = biomarkers.find((biomarker) => {
                  return biomarker.id === element.biomarkerId;
                });

                const previousAnalysis = analysis.previousAnalysis?.patientAnalysisBiomarkers?.find(
                  (previousElement) => {
                    return previousElement.biomarkerId === element.biomarkerId;
                  },
                );

                return (
                  biomarker && (
                    <BiomarkerItem
                      key={biomarker.id}
                      biomarker={biomarker}
                      value={element.value}
                      previousValue={previousAnalysis?.value}
                      hasPreviousAnalysis={hasPreviousAnalysis}
                    />
                  )
                );
              })}
            </Container>
          </div>
        ) : (
          <div className={classNames('flexbox', 'align-center', 'justify-center')} style={{ height: '100vh' }}>
            <Spinner variant={'secondary'} radius={30} />
          </div>
        )}
        {isMobile && (
          <ButtonDefault
            text={t('getResult')}
            onClick={role === RolesEnum.PATIENT ? handleExportInPdfClick : handlePrintPatientAnalysis}
            containerClassNames={classNames(styles['generate-button'])}
            isDisabled={pdfLoading || pdfDownloading}
            isLoading={pdfLoading && pdfDownloading}
          />
        )}
      </>
    </LayoutWithBackOptions>
  );
};
