import * as amplitude from '@amplitude/analytics-browser';
import { Analytics, ValidPropertyType } from 'types/analytics';

export class AmplitudeAnalytics implements Analytics {
  apiKey: string;
  isInitialized = false;
  identifyEvent = new amplitude.Identify();
  removeIdentifyEvent = new amplitude.Identify();

  constructor(apiKey: string) {
    this.apiKey = apiKey;
    this.initialize();
  }

  private initialize() {
    if (!this.isInitialized) {
      amplitude.init(this.apiKey, {
        defaultTracking: {
          attribution: true,
          pageViews: true,
          sessions: true,
          formInteractions: false,
          fileDownloads: false,
        },
      });

      this.isInitialized = true;
    }
  }
  private identify() {
    amplitude.identify(this.identifyEvent);
  }

  setUser(id?: string) {
    amplitude.setUserId(id);
  }

  trackEvent(
    eventInput: string | amplitude.Types.BaseEvent,
    eventProperties?: Record<string, any>,
    eventOptions?: amplitude.Types.EventOptions | undefined,
  ) {
    amplitude.track(eventInput, eventProperties, eventOptions);
  }

  setEvent(property: string, value: ValidPropertyType) {
    this.identifyEvent.set(property, value);

    this.identify();
  }

  reset() {
    amplitude.reset();
  }
  removeEvent(property: string, value: amplitude.Types.ValidPropertyType) {
    this.removeIdentifyEvent.remove(property, value);

    amplitude.identify(this.removeIdentifyEvent);
  }
}
